<template lang="pug">
  .save-modal
    .header
      FaIcon.check-icon(icon="check-circle")
      span.title {{ $t("plan_settings.basic_settings.successfully_changed") }}
    .question {{ $t("plan_settings.basic_settings.templates.confirm_save_as_template") }}
    form.radio-group(@submit.prevent="confirmButton")
      .radio
        input(
          type="radio"
          ref="radio"
          id="do-not-save"
          :value="doNotSave"
          v-model="$v.type.$model"
          @keyup.enter="confirmButton"
        )
        label(for="do-not-save") {{ $t("plan_settings.basic_settings.templates.do_not_save") }}
      .radio(v-if="template.id")
        input(
          type="radio"
          id="overwrite-template"
          :value="overwriteTemplate"
          v-model="$v.type.$model"
          @keyup.enter="confirmButton"
        )
        label(for="overwrite-template") {{ $t("plan_settings.basic_settings.templates.overwrite_template") }}
      .radio.new-template
        input(
          type="radio"
          id="save-as-new-template"
          :value="saveAsNewTemplate"
          v-model="$v.type.$model"
          @keyup.enter="confirmButton"
        )
        label(for="save-as-new-template") {{ $t("plan_settings.basic_settings.templates.save_as_new_template") }}
        .new-template-form-field
          BFormInput.form-control(
            :placeholder="$t('plan_settings.basic_settings.templates.table.template_name')"
            :class="{ 'is-invalid': $v.name.$error }"
            :disabled="!isNewTemplateChecked"
            v-model="$v.name.$model"
          )
          BFormInvalidFeedback(v-if="!$v.name.isUnique")
            | {{ $t("plan_settings.basic_settings.templates.validations.name_already_exists") }}
    .action
      AppButton.confirm(
        title="actions.ok"
        :disabled="!isChecked"
        @click="confirmButton"
      )
</template>

<script>
  // store modules
  import basicSettingsModule from "@/config/store/price_management/plan_settings/basic_settings"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import { validationMixin } from "vuelidate"

  // misc
  import { map } from "lodash-es"
  import { required, requiredIf } from "vuelidate/lib/validators"
  import { SAVE_AS_NEW_TEMPLATE, OVERWRITE_TEMPLATE, DO_NOT_SAVE } from "@/config/constants"

  const basicSettingsMixin = withStoreModule(basicSettingsModule, {
    name: "basicSettings",
    actions: {
      createTemplate: "CREATE_TEMPLATE",
      updateTemplate: "UPDATE_TEMPLATE"
    }
  })

  export default {
    props: {
      templates: {
        type: Array,
        default: () => new Array()
      },
      template: {
        type: Object,
        default: () => new Object()
      }
    },

    data() {
      return {
        type: DO_NOT_SAVE,
        name: "",
        saveAsNewTemplate: SAVE_AS_NEW_TEMPLATE,
        overwriteTemplate: OVERWRITE_TEMPLATE,
        doNotSave: DO_NOT_SAVE
      }
    },

    mounted() {
      this.focusRadio()
    },

    mixins: [basicSettingsMixin, validationMixin],

    validations: {
      type: {
        required
      },

      name: {
        required: requiredIf(function(nested) {
          return nested.type === SAVE_AS_NEW_TEMPLATE
        }),
        isUnique: (name, self) => {
          return name && self.type === SAVE_AS_NEW_TEMPLATE ? !self.existingTemplateNames.includes(name) : true
        }
      }
    },

    computed: {
      existingTemplateNames() {
        return map(this.templates, "template_name")
      },

      isChecked() {
        return this.type.length > 0
      },

      isNewTemplateChecked() {
        return this.type === SAVE_AS_NEW_TEMPLATE
      }
    },

    components: {
      RadioButtons: () => import("@/pages/PriceManagement/GeneralSettings/RuleSettings/RadioButtons"),
      AppButton: () => import("@/components/elements/AppButton")
    },

    methods: {
      confirmButton() {
        this.$v.$touch()
        if (this.$v.$invalid) return
        switch (this.type) {
          case OVERWRITE_TEMPLATE:
            this.updateTemplate(this.template)
            break
          case SAVE_AS_NEW_TEMPLATE:
            this.createTemplate(this.name)
            break
        }
        this.$emit("close")
      },

      focusRadio() {
        this.$refs.radio.focus()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .save-modal
    font-size: 0.8rem

    .header
      align-items: center
      display: flex

      .check-icon
        color: $default-green
        font-size: 1.5rem
        margin-right: 8px

      .title
        font-size: 1.25rem
        font-weight: 500

  .question
    margin-top: 20px

  .radio-group
    display: block
    width: 100%

    .radio
      margin-top: 13px
      display: flex
      align-items: center

      label
        margin-left: 12px
        margin-bottom: unset
        width: 100%

    .new-template
      margin-top: 8px

      &-form-field
        width: 100%

        input
          font-size: 0.8rem
          margin: 0

  .action
    margin-top: 20px
    text-align: right

    .confirm
      font-weight: normal
      background: $default-purple
      color: $default-white
</style>
