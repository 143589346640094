import { NO, MATCH, BUSINESS_HOURS_OPTIONS, DEADLINE_HOLIDAYS_OPTIONS } from "@/config/constants"
import { required, requiredIf, between } from "vuelidate/lib/validators"

export const emptyRakutenPlan = () => ({
  title_pc: null,
  title_mobile: null,
  importance: {
    id: 1,
    name: "1"
  },
  text_pc: null,
  text_mobile: null,
  basic_price: {
    included: {
      day1: null,
      overnight: null,
      days2: null,
      days3: null,
      late: null
    },
    optional: {
      day1: null,
      overnight: null,
      days2: null,
      days3: null,
      late: null
    }
  },
  closing: {
    closing_type: MATCH,
    hours_before: null,
    end_day: null,
    o_clock: null
  },
  sales_start_date: {
    sales_start_date_type: NO,
    hours_before: null,
    end_day: null,
    o_clock: null
  },
  points: null
})

export const rakutenValidations = insurance => ({
  ...rakutenPlanValidations(),
  basic_price: { ...basicPriceValidations(insurance) }
})

const rakutenPlanValidations = () => ({
  title_pc: {
    required
  },
  title_mobile: {
    required: false
  },
  importance: {
    required: false
  },
  text_pc: {
    required: false
  },
  text_mobile: {
    required: false
  },
  closing: {
    closing_type: {
      required
    },
    hours_before: {
      required: requiredIf(function({ closing_type }) {
        return BUSINESS_HOURS_OPTIONS.includes(closing_type)
      })
    },
    end_day: {
      required: requiredIf(function({ closing_type }) {
        return DEADLINE_HOLIDAYS_OPTIONS.includes(closing_type)
      })
    },
    o_clock: {
      required: requiredIf(function({ closing_type }) {
        return DEADLINE_HOLIDAYS_OPTIONS.includes(closing_type)
      })
    }
  },
  sales_start_date: {
    sales_start_date_type: {
      required
    },
    hours_before: {
      required: requiredIf(function({ sales_start_date_type }) {
        return BUSINESS_HOURS_OPTIONS.includes(sales_start_date_type)
      })
    },
    end_day: {
      required: requiredIf(function({ sales_start_date_type }) {
        return DEADLINE_HOLIDAYS_OPTIONS.includes(sales_start_date_type)
      })
    },
    o_clock: {
      required: requiredIf(function({ sales_start_date_type }) {
        return DEADLINE_HOLIDAYS_OPTIONS.includes(sales_start_date_type)
      })
    }
  },
  points: {
    required,
    between: between(0, 100)
  }
})

const basicPriceValidations = insurance => ({
  [insurance]: {
    day1: {
      required,
      minValue: value => value > 0
    },
    overnight: {
      required,
      greaterThanCheck: (value, self) => value > self?.day1
    },
    days2: {
      required,
      greaterThanCheck: (value, self) => value > self?.overnight
    },
    days3: {
      required,
      greaterThanCheck: (value, self) => value > self?.days2
    },
    late: {
      required,
      minValue: value => value > 0
    }
  }
})
