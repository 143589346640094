import { isEmpty, cloneDeep } from "lodash-es"
import { validationMixin } from "vuelidate"
import { emptyJalanPlan, jalanValidations } from "./jalanValidations"
import { emptyRakutenPlan, rakutenValidations } from "./rakutenValidations"

const emptyPlans = {
  jalan: emptyJalanPlan,
  rakuten: emptyRakutenPlan
}

export default {
  mixins: [validationMixin],

  validations() {
    return {
      jalan: jalanValidations(),
      rakuten: rakutenValidations(this.insurance)
    }
  },

  data() {
    return {
      jalan: { ...emptyJalanPlan() },
      rakuten: { ...emptyRakutenPlan() }
    }
  },

  watch: {
    plan(newValue) {
      const { otaName } = this
      this.currentValidator.$model = isEmpty(newValue) ? emptyPlans[otaName]() : cloneDeep(newValue)
      this.currentValidator.$reset()
    }
  },

  computed: {
    insurance() {
      return this.filters.insurance
    },

    otaName() {
      return this.filters.ota?.rawName
    },

    isValid() {
      return !this.currentValidator?.$invalid
    },

    currentValidator() {
      return this.$v[this.otaName] || {}
    },

    currentPlan() {
      return this.currentValidator.$model || {}
    }
  },

  methods: {
    validate() {
      this.currentValidator.$touch()
    },

    resetValidation() {
      this.currentValidator.$reset()
    }
  }
}
