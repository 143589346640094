/* eslint-disable no-unused-vars */
import {
  DEFAULT,
  END_DATE,
  START_END_DATE,
  MAX_OPTIONS,
  FEE_OPTIONS,
  PERIOD_SPECIFIED_FEE,
  STANDARD,
  SELECTABLE
} from "@/config/constants"
import { required, requiredIf } from "vuelidate/lib/validators"

export const emptyJalanPlan = () => ({
  id: null,
  plan_name: null,
  basic_price: {
    optional: {
      day1: null,
      overnight: null,
      days2: null,
      days3: null,
      late: null
    },
    included: {
      day1: null,
      overnight: null,
      days2: null,
      days3: null,
      late: null
    }
  },
  sources_cancellation_fee_rule: null,
  car_options: [],
  insurances: [],
  description: null,
  availability_period: {
    period_type: DEFAULT,
    start_day: null,
    start_hour: null,
    end_day: null,
    end_hour: null
  },
  cancellation_period: {
    period_type: DEFAULT,
    end_day: null,
    end_hour: null
  },
  points: DEFAULT
})

export const jalanValidations = () => ({
  id: { required: false },
  plan_name: {
    required
  },
  basic_price: {
    included: {
      day1: {
        required,
        minValue: value => value > 0
      },
      overnight: {
        required,
        greaterThanCheck: (value, self) => value > self?.day1
      },
      days2: {
        required,
        greaterThanCheck: (value, self) => value > self?.overnight
      },
      days3: {
        required,
        greaterThanCheck: (value, self) => value > self?.days2
      },
      late: {
        required,
        minValue: value => value > 0
      }
    },
    optional: {
      day1: {
        required,
        minValue: value => value > 0
      },
      overnight: {
        required,
        greaterThanCheck: (value, self) => value > self?.day1
      },
      days2: {
        required,
        greaterThanCheck: (value, self) => value > self?.overnight
      },
      days3: {
        required,
        greaterThanCheck: (value, self) => value > self?.days2
      },
      late: {
        required,
        minValue: value => value > 0
      }
    }
  },
  sources_cancellation_fee_rule: {
    id: { required },
    name: { required },
    active: { required }
  },
  car_options: {
    $each: {
      sources_car_option: {
        id: { required },
        name: { required },
        option_type: { required }
      },
      selectability_type: { required },
      fee_type: {
        required: requiredIf(function({ selectability_type }) {
          return [STANDARD, SELECTABLE].includes(selectability_type)
        })
      },
      fee: {
        required: requiredIf(function({ fee_type }) {
          return FEE_OPTIONS.includes(fee_type)
        })
      },
      max_fee: {
        required: false
      },
      fees: {
        required: requiredIf(function({ fee_type }) {
          return fee_type === PERIOD_SPECIFIED_FEE
        }),
        $each: {
          day: { required },
          fee: { required }
        }
      }
    }
  },
  insurances: {
    $each: {
      sources_insurance: {
        id: { required },
        name: { required }
      },
      insurance_type: { required },
      selectability_type: { required },
      fee_type: {
        required: requiredIf(function({ selectability_type }) {
          return selectability_type === SELECTABLE
        })
      },
      fee: {
        required: requiredIf(function({ fee_type }) {
          return FEE_OPTIONS.includes(fee_type)
        })
      },
      max_fee: {
        required: false
      }
    }
  },
  description: {
    required: false
  },
  availability_period: {
    period_type: {
      required
    },
    end_day: {
      required: requiredIf(function({ period_type }) {
        return period_type === END_DATE || period_type === START_END_DATE
      })
    },
    end_hour: {
      required: requiredIf(function({ period_type }) {
        return period_type === END_DATE || period_type === START_END_DATE
      })
    },
    start_day: {
      required: requiredIf(function({ period_type }) {
        return period_type === START_END_DATE
      })
    },
    start_hour: {
      required: requiredIf(function({ period_type }) {
        return period_type === START_END_DATE
      })
    }
  },
  cancellation_period: {
    period_type: {
      required
    },
    end_day: {
      required: requiredIf(function({ period_type }) {
        return period_type === END_DATE
      })
    },
    end_hour: {
      required: requiredIf(function({ period_type }) {
        return period_type === END_DATE
      })
    }
  },
  points: {
    required
  }
})

export const insuranceValidation = required => ({
  optionalAdditional: {
    required: false
  },
  includedAdditional: {
    required: false
  },
  includedStandard: {
    required: false
  },
  fees: {
    $each: {
      option: {
        required
      },
      max_fee: {
        required
      },
      fee: {
        required
      }
    }
  }
})

export const carOptionsValidation = (required, requiredIf) => ({
  $each: {
    sources_car_option_id: { required },
    selectability_type: { required },
    fee_type: {
      required: requiredIf(function({ selectability_type }) {
        return [STANDARD, SELECTABLE].includes(selectability_type)
      })
    },
    fee: {
      required: requiredIf(function({ fee_type }) {
        return FEE_OPTIONS.includes(fee_type)
      })
    },
    max_fee: {
      required: requiredIf(function({ fee_type }) {
        return MAX_OPTIONS.includes(fee_type)
      })
    },
    fees: {
      required: requiredIf(function({ fee_type }) {
        return fee_type === PERIOD_SPECIFIED_FEE
      }),
      $each: {
        day: {
          required
        },
        fee: {
          required
        }
      }
    }
  }
})
